<template>
    <div class="container">
        <div class="row " style="max-width: 478px">
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Evento</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_EVENTO">
                        <option v-for="(evento, index) in getterEvento.options" :key="index" :value="evento.opcao">
                            {{ evento.opcao }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Tipo</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_TIPO">
                        <option v-for="( tipo, index) in getterTipo.options" :key="index" :value="tipo.opcao">
                            {{ tipo.opcao }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Periodo</label><br>
                    <div class="row mt-2">
                        <div class="row">
                            <div class="d-flex align-items-center justify-content-between">
                                <span style="width: 3rem;">Inicio: </span>
                                <input type="date" class="form-control input-datepicker" id="date"
                                    :value="getterPeriodo.inicial.data" @input="CHANGE_PERIODO_DATA_INICIAL" />
                                às
                                <input type="time" class="form-control input-timepicker" id="time"
                                    :value="getterPeriodo.inicial.hora" @input="CHANGE_PERIODO_HORA_INICIAL" />
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-2">
                                <span style="width: 3rem;">Fim: </span>
                                <input type="date" class="form-control input-datepicker" id="date"
                                    :value="getterPeriodo.final.data" @input="CHANGE_PERIODO_DATA_FINAL" />
                                às
                                <input type="time" class="form-control input-timepicker" id="time"
                                    :value="getterPeriodo.final.hora" @input="CHANGE_PERIODO_HORA_FINAL" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Zona</label><br>
                    <input type="text" class="input-text" placeholder="1 ao 17 ou Todos" @input="CHANGE_VALUE_ZONA">
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Nivel</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_NIVEL">
                        <option v-for="( nivel, index) in getterNivel.options" :key="index" :value="nivel.opcao">
                            {{ nivel.opcao }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: 'CardOpcoesAlertas',
    computed: {
        ...mapGetters('alerta', [
            'getterEvento',
            'getterTipo',
            'getterPeriodo',
            'getterZona',
            'getterNivel'
        ]),
    },
    methods: {
        ...mapMutations('alerta', [
            'CHANGE_SELECTED_EVENTO',
            'CHANGE_SELECTED_TIPO',
            'CHANGE_PERIODO_DATA_INICIAL',
            'CHANGE_PERIODO_DATA_FINAL',
            'CHANGE_PERIODO_HORA_INICIAL',
            'CHANGE_PERIODO_HORA_FINAL',
            'CHANGE_VALUE_ZONA',
            'CHANGE_SELECTED_NIVEL'
        ]),
    }
}
</script>