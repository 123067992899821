<template>
    <div class="container my-5 conteudo-card-mensagem">
        <div class="card-cabecalho mt-5">
            PREVIEW DA MENSAGEM POR WPP
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="sub-title-mensagem ">
                    <p class="text-capitalize">WhatsApp</p>
                    <!-- <p class="text-uppercase">fra_01</p> -->
                    <p class="text-capitalize">Prefeitura de Porto Alegre – Defesa Civil.</p>
                    <!-- <p class="text-uppercase">Alerta</p> -->
                </div>
                <div class="text-card-mensagem">
                    <p>
                        Prefeitura de Porto Alegre: Previsão de {{ getterEvento.selected }} {{ naoInformar }} na sua
                        regiao
                        de {{ formmatDate(getterPeriodo.inicial.data) }} às {{ getterPeriodo.inicial.hora }} até
                        {{ formmatDate(getterPeriodo.final.data) }} às {{ getterPeriodo.final.hora }}.
                        Em caso de emergência, ligue 199 ou 193.
                    </p>
                </div>
            </div>

            <div class="mx-2 mt-0 py-0">
                <img :src="urlImage" class="card-img-bottom img-card-wpp">
            </div>
            <div>
                <p class="card-text rodape-card-wpp">💡 Você recebeu esta mensagem, pois tem este local ativo em seus
                    alertas da defesa civil.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewWPP',
    computed: {
        ...mapGetters('alerta', [
            'getterEvento',
            'getterTipo',
            'getterPeriodo',
            'getterNivel'
        ]),
        naoInformar() {
            if (this.getterTipo.selected === 'Não Informar​') return ''
            return this.getterTipo.selected
        },
        urlImage() {
            if (parseInt(this.getterNivel.selected) === 2) return this.getterNivel.options[1].url
            if (parseInt(this.getterNivel.selected) === 3) return this.getterNivel.options[2].url
            if (parseInt(this.getterNivel.selected) === 4) return this.getterNivel.options[3].url
            return this.getterNivel.options[0].url
        },
    },
    methods: {
        formmatDate(date) {
            return date.split("-").reverse().join("/");
        }
    }
}
</script>