import axios from "axios";

console.log(
  "backend: ",
  process.env.VUE_APP_ENDPOINT_BACKEND ?? window._env_.VUE_APP_ENDPOINT_BACKEND
);

const api = axios.create({
  baseURL:
    process.env.VUE_APP_ENDPOINT_BACKEND ??
    window._env_.VUE_APP_ENDPOINT_BACKEND,
  headers: {
    "x-api-key":
      process.env.VUE_APP_X_API_KEY ?? window._env_.VUE_APP_X_API_KEY,
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;
