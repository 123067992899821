<template>
  <div class="sidenav-lateral offcanvas offcanvas-start show text-bg-dark side-nav-style noneBorder" data-bs-scroll="true"
    data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
    <div class="offcanvas-header">
      <div class="container d-flex justify-content-center">
        <span class="nav-item">
          <a class="navbar-brand" href="#">
            <img src="../../../public/img/logo.png" width="53px" height="59px" />
          </a>
        </span>
      </div>
    </div>
    <!-- <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    <div class="offcanvas-body p-0">
      <sidenav-list />
    </div>
  </div>
</template>
<script>
import SidenavList from "./SidenavList.vue";

export default {
  name: "index",
  components: {
    SidenavList,
  },
};
</script>
