<template>
    <div class="container conteudo-card-mensagem">
        <div class="card-cabecalho">
            PREVIEW DA MENSAGEM POR SMS
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="text-card-mensagem">
                    <p>
                        Prefeitura de Porto Alegre: Previsão de {{ getterEvento.selected }} {{ naoInformar }} na sua
                        regiao
                        de {{ formmatDate(getterPeriodo.inicial.data) }} às {{ getterPeriodo.inicial.hora }} até
                        {{ formmatDate(getterPeriodo.final.data) }} às {{ getterPeriodo.final.hora }}.
                        Em caso de emergência, ligue 199 ou 193.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewSMS',
    computed: {
        ...mapGetters('alerta', [
            'getterEvento',
            'getterTipo',
            'getterPeriodo',
        ]),
        naoInformar() {
            if (this.getterTipo.selected === 'Não Informar​') return ''
            return this.getterTipo.selected
        }
    },
    methods: {
        formmatDate(date) {
            return date.split("-").reverse().join("/");
        }
    }
}
</script>