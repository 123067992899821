export const extractStrings = (value) => {
  let comma = value.split(/,\s| e /);
  let newArr = comma.map((el) => {
    if (
      (!isNaN(parseInt(el)) && parseInt(el) >= 1 && parseInt(el) <= 17) ||
      el.toLowerCase() == "todos"
    ) {
      return !isNaN(parseInt(el)) ? parseInt(el) : el.toLowerCase();
    } else {
      return null;
    }
  });
  //verifica se existe indice null em um array e devolve
  if (isNull(newArr)) return null;
  // verifica se existe indice com valor Todos/todos
  if (checkValue(newArr)) return ["todos"];

  return newArr;
};

const isNull = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === null) {
      return true;
    }
  }
  return false;
};

const checkValue = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].toString().toLowerCase() === "todos") {
      return true;
    }
  }
  return false;
};
