import { createStore } from "vuex";
import alerta from "./modules/alerta";

const store = createStore({
  modules: {
    alerta: alerta,
  },
});

export default store;
