<template>
    <!-- <div class="container px-2"> -->
    <div class="row px-2">
        <div class="col-6 px-3">
            <button type="button" class="btn-cancelar" @click="onLoading">
                <span><img src="../../assets/img/icons/icon-x-circle.png"></span>
                <span>Cancelar</span>
            </button>
        </div>
        <div class="col-6 px-3">
            <button type="button" :class="['btn-enviar', { 'btn-disabled': getterDisabled }]" @click="send()"
                :disabled="getterDisabled">
                <span><img src="../../assets/img/icons/icon-check-circle.png"></span>
                <span>Enviar</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'Btn-group',
    computed: {
        ...mapGetters('alerta', ['getterDisabled'])
    },
    methods: {
        onLoading() {
            window.location.reload();
        },
        ...mapActions('alerta', ['send'])
    }
}
</script>