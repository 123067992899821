import api from "@/services/api";
import { formattDate, formattDatePt } from "@/utils/date";
import { getCurrentTime } from "@/utils/hora";
import { extractStrings } from "@/utils/input-zona";

const alerta = {
  namespaced: true,
  state: {
    evento: {
      options: [
        { opcao: "Alagamento" },
        { opcao: "Baixa Umidade do Ar" },
        { opcao: "Chuva" },
        { opcao: "Deslizamento" },
        { opcao: "Enxurrada" },
        { opcao: "Granizo" },
        { opcao: "Incêndio" },
        { opcao: "Inundação" },
        { opcao: "Rompimento ou Colapso de Barragem" },
        { opcao: "Vendaval" },
        { opcao: "Temperatura" },
      ],
      selected: "Alagamento",
    },
    tipo: {
      options: [
        { opcao: "Não Informar​" },
        { opcao: "Fraca" },
        { opcao: "Fraca–Moderada" },
        { opcao: "Moderada" },
        { opcao: "Moderada–Forte" },
        { opcao: "Forte" },
        { opcao: "Alta" },
        { opcao: "Baixa" },
      ],
      selected: "Não Informar​",
    },
    periodo: {
      inicial: {
        data: formattDate(new Date().toLocaleDateString()),
        hora: getCurrentTime(),
      },
      final: {
        data: formattDate(new Date().toLocaleDateString()),
        hora: getCurrentTime(),
      },
    },
    zona: "",
    nivel: {
      options: [
        { opcao: 1, url: "../../../img/alertas/verde.jpg" },
        { opcao: 2, url: "../../../img/alertas/amarelo.jpg" },
        { opcao: 3, url: "../../../img/alertas/vermelho.jpg" },
        { opcao: 4, url: "../../../img/alertas/preto.jpg" },
      ],
      selected: 1,
    },
    mensagem: "",
    disabed: false,
  },
  getters: {
    getterEvento: (state) => state.evento,
    getterTipo: (state) => state.tipo,
    getterPeriodo: (state) => state.periodo,
    getterZona: (state) => state.zona,
    getterNivel: (state) => state.nivel,
    getterMensagem: (state) => state.mensagem,
    getterDisabled: (state) => state.disabed,
  },
  mutations: {
    CHANGE_SELECTED_EVENTO: (state, value) => {
      state.evento.selected = value.target.value;
    },
    CHANGE_SELECTED_TIPO: (state, value) => {
      state.tipo.selected = value.target.value;
    },
    CHANGE_PERIODO_DATA_INICIAL: (state, value) => {
      state.periodo.inicial.data = value.target.value;
    },
    CHANGE_PERIODO_DATA_FINAL: (state, value) => {
      state.periodo.final.data = value.target.value;
    },
    CHANGE_PERIODO_HORA_INICIAL: (state, value) => {
      state.periodo.inicial.hora = value.target.value;
    },
    CHANGE_PERIODO_HORA_FINAL: (state, value) => {
      state.periodo.final.hora = value.target.value;
    },
    CHANGE_VALUE_ZONA: (state, value) => {
      state.zona = value.target.value;
    },
    CHANGE_SELECTED_NIVEL: (state, value) => {
      state.nivel.selected = value.target.value;
    },
  },
  actions: {
    async send({ commit, state }) {
      if (!state.zona) return alert("Campo 'zona' vazio.");
      if (extractStrings(state.zona) !== null) {
        try {
          state.disabed = true;
          await api.post("/api/send", {
            evento: state.evento.selected,
            tipo: state.tipo.selected,
            periodo: {
              inicial: `${formattDatePt(state.periodo.inicial.data)} às ${
                state.periodo.inicial.hora
              } `,
              final: `${formattDatePt(state.periodo.final.data)} às ${
                state.periodo.final.hora
              } `,
            },
            zona: extractStrings(state.zona),
            nivel: parseInt(state.nivel.selected),
          });
          state.mensagem = "Alerta enviado com sucesso!";
          setTimeout(() => {
            state.mensagem = "";
            location.reload();
          }, 8000);
          clearTimeout();
          state.disabed = false;
        } catch (error) {
          console.log(error);
          state.mensagem = "Algo aconteceu";
          setTimeout(() => {
            state.mensagem = "";
            location.reload();
          }, 20000);
          clearTimeout();
          state.disabed = false;
        }
      } else {
        return alert("Campo 'zona' deve conter valores entre 1 e 17 ou Todos.");
      }
    },
  },
};

export default alerta;
